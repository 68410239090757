body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus,
button:focus,
input[type='checkbox']:focus {
  outline: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
}

h4 {
  margin-top: 0.5em;
  text-decoration: underline;
}

.container-fluid {
  margin-bottom: 5em;
}

.text-grey {
  color: #8c8c8c;
}

.toast {
  position: absolute;
  top: 1em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.custom-input {
  margin-left: 1em;
}

.show-modal {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.5em;
}

.float-right {
  margin-top: 0.5em;
}

.header-switch {
  margin: 1em 0;
  text-align: center;
}

.header-link {
  text-align: center;
}

.header-link p {
  color: #007bff;
  cursor: pointer;
}

.header-link p:hover {
  text-decoration: underline;
}

.btn-close {
  padding: 0;
  border: none;
  background: none;
}

.btn-close:after {
  display: inline-block;
  content: '\00d7';
}

.endora {
  display: none;
}
